/** @format */

import { DialogService } from 'aurelia-dialog';
import alertsService from 'services/api/alertsService';

import { AlertDetails } from 'components/alert-details/alert-details';
import './alerts.scss';

const ALERT_PROCESSED_CSSCLASS = 'processed';

const ALERT_TO_LIST = a => {
  a.cssClass = `alert ${
    a.processed ? ALERT_PROCESSED_CSSCLASS : 'unprocessed'
  } ${a.closed ? 'closed' : 'open'}`;
  return a;
};

export class Alerts {
  static inject() {
    return [DialogService];
  }
  constructor(_DialogService) {
    this.dialogService = _DialogService;
    //
    this.alertsService = alertsService;
    //

    this.gridHeaders = [
      { label: '', sortable: false, cssClass: 'image' },
      { label: 'name', sortable: true },
      { label: 'asset_name', sortable: true, sortField: 'assetName' },
      { label: 'type', sortable: true },
      { label: 'date', sortable: false },
      { label: 'processed', sortable: false },
      { label: 'channels' }
    ];

    this.gridColumns = [
      { type: 'icon', field: 'icon' },
      { type: 'text', field: 'name' },
      { type: 'text', field: 'assetName' },
      { type: 'text', field: 'type', translate: true },
      { type: 'date', field: 'startGpsTimestamp', format: 'DateTime' },
      { type: 'text', field: 'processedDate' },
      { type: 'iconlist', field: 'channelIcons' }
    ];

    this.sortProperties = {
      field: 'name',
      reverse: false
    }

    this.loading = false;
    this.alerts = [];
    this.alertsList = [];
    this.filters = null;
    // this.error = null;

    // this.selectedAlert = null;
    this.onAlertSelected = this.onAlertSelected.bind(this);
    this.onAlertFilterChanged = this.onAlertFilterChanged.bind(this);
  }

  attached() {
    this.error = null;
  }

  loadAlerts(dtfrom, dtto) {
    this.loading = true;
    this.alertsService.getOccurrences(null, null, dtfrom, dtto).then(alerts => {
      this.alerts = alerts.map(ALERT_TO_LIST);      
      this.filterByTypes(this.filterTypes);
      this.loading = false;
    });
  }

  onAlertSelected(alert) {
    if (alert) {
      this.dialogService
        .open({ viewModel: AlertDetails, model: alert })
        .whenClosed(result => {
          if (!result.wasCancelled) {
            let updated = result.output;
            if (updated && updated.processed) {
              //update grid entry
              alert.processed = updated.processed;
              alert.cssClass = ALERT_PROCESSED_CSSCLASS; //I dont like this. REFACTOR!
            }
          }
        });
    }
  }

  onAlertFilterChanged(filters = {}) {
    if (filters.types) {
      this.filterByTypes(filters.types);
    }
    if (filters.dates) {
      this.filterbyDate(filters.dates);
    }
  }

  filterByTypes(types = []) {
    let filtered = this.alerts;

    this.filterTypes = types;
    if (types.length) {
      filtered = this.alerts.filter(x => {
        return types.indexOf(x.type) > -1;
      });
    }
    this.alertsList = filtered;
  }

  filterbyDate(dates = {}) {
    if (dates.dtfrom && dates.dtto) {
      this.loadAlerts(dates.dtfrom, dates.dtto);
    }
  }
}
