/** @format */

import { bindable, inject } from 'aurelia-framework';
import { AlertTypes } from 'services/api/alertsService';
import { TranslationService } from 'services/translationService';

const ID_ALL = 0;

@inject(TranslationService)
export class AlertFilters {
  @bindable onAlertfilterChanged;
  constructor(_TranslationService) {
    this.ts = _TranslationService;

    this.alertTypes = [
      {
        id: 'all',
        value: ID_ALL,
        name: this.ts.getCap('all'),
        disabled: true,
        default: true,
        hide: true
      }
    ];

    for (let type in AlertTypes) {
      let item = AlertTypes[type];
      this.alertTypes.push({
        id: type,
        value: type,
        name: this.ts.getCap(item)
      });
    }

    this.onDateFilterChanged = this.onDateFilterChanged.bind(this);
    this.onAlertTypeChanged = this.onAlertTypeChanged.bind(this);
  }

  onAlertTypeChanged(options) {
    if (this.onAlertfilterChanged) {
      let filter = options.map(x => {
        return x.value;
      });
      this.onAlertfilterChanged({ types: filter });
    }
  }

  onDateFilterChanged(dtfrom, dtto) {
    if (this.onAlertfilterChanged && dtfrom && dtto) {
      this.onAlertfilterChanged({ dates: { dtfrom, dtto } });
    }
  }
}
